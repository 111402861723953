/**
 * Alert Messages
 *
 */
export const Messages={
    "stock_delete":{
       "title":"Confirm",
       "message":"Are you sure to you want to delete \"{item_description}\" from warehouse \"{location_name}\" : quantity {quantity}-{unit_name} ?"
    },
    "stock_cancel_unavailable":{
       "title":"Confirm",
       "message":"Are you sure you want to release \"{item_description}\" from warehouse \"{location_name}\"  : quantity {quantity}-{unit_name} ?"
    },
    "emergency_operator_required":{
       "title":"Access denied",
       "message":"You do not have sufficient access rights for this. Contact your Administrator"
    },
    "emergency_owner_mail_sent":{
       "title":"Mail Sent",
       "message":"Email sent to {email}"
    },
    "emergency_owner_mail_not_sent":{
       "title":"Failed to Send",
       "message":"Unable to send mail to {email}"
    },
    "emergency_srf_cancel":{
       "title":"Confirm",
       "message":"Are you sure you want to cancel {srf_number}?"
    },
    "emergency_package_cancel":{
       "title":"Confirm",
       "message":"Are you sure you want to cancel {package_number}?"
    },
    "emergency_srf_edit_expired":{
       "title":"Session Expired!",
       "message":"This SRF cannot be edited"
    },
    "emergency_srf_form_warn_from":{
       "title":"Action Required",
       "message":"\"Please choose \"Ship From\" (Country and Warehouse) first\""
    },
    "emergency_srf_form_warn_sending":{
       "title":"Action Required",
       "message":"\"Please choose \"Sending Organisation\" first\""
    },
    "emergency_srf_form_error_required":{
       "title":"Alert",
       "message":"Please enter all required fields for selected rows"
    },
    "emergency_srf_form_error_highlighted":{
       "title":"Alert",
       "message":"Please fill in all fields highlighted in red."
    },
    "emergency_srf_form_token_error_highlighted":{
       "title":"Alert",
       "message":"Please enter up to 5 character for token (only alphanumeric characters)."
    },
    "emergency_pl_form_error_qty":{
       "title":"Alert",
       "message":"The sum of \"Accepted Qty\", \"Damaged Qty\", and \"Missing Qty\" cannot exceed the line item shipped quantity."
    },
    "emergency_pl_form_error_required":{
       "title":"Alert",
       "message":"Please fill all required fields."
    },
    "emergency_invalid_pkg":{
      "title":"Invalid Package",
      "message":"Invalid Package Number"
   },
    "admin_user_approve":{
       "title":"Confirm",
       "message":"Are you sure you want to approve user {name}?"
    },
    "admin_user_suspend":{
       "title":"Confirm",
       "message":"Are you sure to suspend the user {name}?"
    },
    "admin_user_delete":{
      "title":"Confirm",
      "message":"Are you sure you want to delete {name}?"
   },
   "admin_user_cannot_delete":{
      "title":"Denied",
      "message":"This user cannot be deleted!"
   },
    "admin_country_delete":{
       "title":"Confirm",
       "message":"Are you sure you want to delete {name}?"
    },
    "admin_country_cannot_delete":{
       "title":"Denied",
       "message":"This country cannot be deleted, please clean data related to this country and try again"
    },
    "admin_org_approve":{
       "title":"Confirm",
       "message":"Are you sure you want to approve this organisation {name}?"
    },
    "admin_org_suspend":{
       "title":"Confirm",
       "message":"Are you sure you want to suspend this organisation {name}?"
    },
    "admin_org_visible_on":{
       "title":"Confirm",
       "message":"Are you sure to enable visible setting of \"{name}\"?  This will make organisation\'s stock data exposed to all users."
    },
    "admin_org_visible_off":{
      "title":"Confirm",
      "message": 'Are you sure to disable visible setting of "{name}"? This will restrict access of organisation\'s stock data from other organizaitons',
    },
    "admin_org_delete":{
       "title":"Confirm",
       "message":"Are you sure you want to delete {name}?"
    },
    "admin_org_cannot_delete":{
       "title":"Denied",
       "message":"This organisation cannot be deleted, please clean data related to this organisation and try again"
    },
    "admin_unit_package_name_enable":{
      "title":"Confirm",
      "message":"Are you sure you want to {mode} this package name?"
   },
    "mobile_gps_required":{
       "title":"Warning",
       "message":"Location permission must be turned on"
    },
    "mobile_gps_error":{
       "title":"Alert",
       "message":"GPS coordinates cannot be determined. Please enable GPS  in settings and Click on \"Reload GPS\" button, or type latitude and longitude manually as \"latitude,longitude\""
    },
    "mobile_recieve_deny":{
       "title":"Operation denied",
       "message":"This package has been received already."
    },
    "mobile_qr_invalid":{
       "title":"Alert",
       "message":"QR code is invalid. This website allows to scan QR code generated for "+window.location.host
    },
    "mobile_qr_fail":{
       "title":"Alert",
       "message":"QR code scanning failed {error}"
    },
    "mobile_gps_disabled":{
       "title":"Alert",
       "message":"Location must be enabled to use this app"
    }
 };